<template>
    <div v-if="confirm">
        <button class="btn btn-sm" @click="confirm = false">
            {{ $t("Cancel") }}
        </button>
        <button
            class="btn btn-rounded-circle btn-danger border-0"
            @click="ok"
        >
            <i class="fe fe-trash-2"></i>
        </button>
    </div>
    <button
        :class="
            buttonClass
                ? buttonClass
                : 'btn btn-rounded-circle btn-white border-0'
        "
        @click="confirm = true"
        v-else
    >
        <slot></slot>
    </button>
</template>
<script>
export default {
    props: {
        buttonClass: String,
    },
    data() {
        return {
            confirm: false,
        };
    },
    methods: {
        ok() {
            this.confirm = false;
            this.$emit("confirm", true);
        },
    },
};
</script>
